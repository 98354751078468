<template>
  <!-- 订单列表 -->
  <div id="app">
    <div class="center-tit">
      我的订单<span>/My Orders</span>
      <ul>
        <li
          v-for="(item, index) in tabList"
          :key="index"
          :class="idx == index ? 'active' : ''"
          @click="tabNav(index, item.status)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="list-cons">
      <div v-for="(item, index) in list" :key="index" class="list order-list">
        <div class="list-head">
          <p>
            <img
              class="icon"
              src="@/assets/center/order-icon.png"
            />订单编号：{{ item.orderNumber }}
          </p>
          <p class="gray">
            {{ item.createTime }}
          </p>
        </div>
        <div class="listContainer">
          <el-row v-for="(course, indexes) in item.courses" :key="indexes">
            <el-col :span="6">
              <img
                :src="
                  course.coverUrl
                    ? course.coverUrl
                    : '@/assets/center/lesson-demo.png'
                "
                class="imgs"
                style="border: 1px solid #f7f7f7"
              />
            </el-col>
            <el-col :span="12">
              <h2>
                {{ course.name }}
              </h2>
            </el-col>
          </el-row>
          <el-row class="rightContainer">
            <el-col :span="8">
              <div
                v-if="item.paymentMethod && item.paymentMethod.value == 0"
                class="pay"
              >
                <img src="@/assets/center/wechatpay-icon.png" />
              </div>
              <div
                v-if="item.paymentMethod && item.paymentMethod.value == 1"
                class="pay"
              >
                <img src="@/assets/center/alipay-icon.png" />
              </div>
              <div
                v-if="item.paymentMethod && item.paymentMethod.value == 2"
                class="pay"
              >
                <img src="@/assets/center/paypal-icon.png" />
              </div>
              <div
                v-if="item.paymentMethod && item.paymentMethod.value == 3"
                class="pay"
              >
                <img src="@/assets/center/venmo-icon.png" />
              </div>
              <div
                v-if="item.paymentMethod && item.paymentMethod.value == 4"
                class="pay"
              >
                <img src="@/assets/center/zelle-icon.png" />
              </div>
              <div
                v-if="item.paymentMethod && item.paymentMethod.value == 5"
                class="pay"
              >
                <!--                    免费-->
              </div>
              <div
                v-if="item.paymentMethod && item.paymentMethod.value == 6"
                class="pay"
              >
                <!--                    优惠后免费-->
              </div>
              <div
                v-if="item.paymentMethod && item.paymentMethod.value == 7"
                class="pay"
              >
                <img src="@/assets/center/affirm-icon.png" />
              </div>
              <div
                v-if="item.paymentMethod && item.paymentMethod.value == 8"
                class="pay"
              >
                <img src="@/assets/center/huabei-icon.png" />
              </div>
            </el-col>
            <el-col :span="8" :offset="item.paymentMethod ? 0 : 8">
              <div
                v-if="!item.dollarPayAmount || item.dollarPayAmount == 0"
                class="prices"
                style="top: 100px"
              >
                ￥{{ item.rmbPayAmount }}
              </div>
              <div v-else class="prices">${{ item.dollarPayAmount }}</div>
            </el-col>
            <el-col :span="8">
              <a
                v-if="item.status && item.status.value == 0"
                @click="goPay(item)"
                >待支付</a
              >
              <a
                v-if="item.status && item.status.value == 1"
                class="faild"
                style="cursor: inherit"
                >已完成</a
              >
              <a
                v-if="item.status && item.status.value == 2"
                class="faild"
                style="cursor: text; opacity: 0.4"
                >已失效</a
              >
              <a
                v-if="item.status && item.status.value == 3"
                class="faild"
                style="cursor: text; opacity: 0.4"
                >退款中</a
              >
              <a
                v-if="item.status && item.status.value == 4"
                class="faild"
                style="cursor: text; opacity: 0.4"
                >已退款</a
              >
              <a
                v-if="item.status && item.status.value == 5"
                class="faild"
                style="cursor: text; opacity: 0.4"
                >退款失败</a
              >
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div style="text-align: center; padding: 20px">
      <el-pagination
        :current-page.sync="currpage"
        :page-size="pageSize"
        :total="total"
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <!-- 移步到订单中心 -->
    <div v-if="dialogFormVisibleMoving" class="submit-con">
      <div class="box zhuan-box pay-box" style="height: 175px; width: 300px">
        <div class="c-title">订单超时，此订单已失效</div>
        <div class="c-btn">
          <div @click="reload">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryOrders } from "../../../service";

const STATUS_PREPAY = 0;
const STATUS_COMPLETE = 1;
const STATUS_CANCEL = 2;
let orders = [];

export default {
  data() {
    return {
      idx: 0,
      curStatus: -1,
      tabList: [
        {
          status: -1,
          name: "全部",
          checked: true,
        },
        {
          status: 0,
          name: "待支付",
          checked: false,
        },
        {
          status: 1,
          name: "已完成",
          checked: false,
        },
        {
          status: 2,
          name: "已失效",
          checked: false,
        },
      ],
      dialogFormVisibleMoving: false,
      list: [
        // {
        //   id: "1912111218358677",
        //   date: "2019-12-11 12:18:35",
        //   title: "Flutter从入门到进阶 实战携程网App",
        //   price: "500",
        //   paystate: 0,
        // },
        // {
        //   id: "1912111218358678",
        //   date: "2019-12-11 12:18:38",
        //   title: "Flutter从入门到进阶 实战携程网App",
        //   price: "230",
        //   paystate: 1,
        // },
        // {
        //   id: "1912111218358679",
        //   date: "2019-12-11 12:18:35",
        //   title: "Flutter从入门到进阶 实战携程网App",
        //   price: "400",
        //   paystate: 2,
        // },
        // {
        //   id: "1912111218358610",
        //   date: "2019-12-11 12:18:35",
        //   title: "Flutter从入门到进阶 实战携程网App",
        //   price: "280",
        //   paystate: 0,
        // },
        // {
        //   id: "19121112183586212",
        //   date: "2019-12-11 12:18:35",
        //   title: "Flutter从入门到进阶 实战携程网App",
        //   price: "620",
        //   paystate: 0,
        // },
        // {
        //   id: "1912111218358677",
        //   date: "2019-12-11 12:18:35",
        //   title: "Flutter从入门到进阶 实战携程网App",
        //   price: "440",
        //   paystate: 2,
        // },
        // {
        //   id: "1912111218358677",
        //   date: "2019-12-11 12:18:35",
        //   title: "Flutter从入门到进阶 实战携程网App",
        //   price: "440",
        //   paystate: 3,
        // },
      ],
      pageSize: 5, //每一页的数据
      currpage: 1, //当前页
      total: 0, //所有的数据
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.path = this.$route.path;

    this.queryMyOrders("");
  },
  methods: {
    reload() {
      window.location.reload();
    },
    tabNav(index, status) {
      console.log(index, status);
      this.pageSize = 5;
      this.currpage = 1;
      this.total = 0;
      this.queryMyOrders(status);
      this.curStatus = status;
      this.tabList.forEach((element, i) => {
        if (i == index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
      this.idx = index;
      this.setOrdersByStatus();
    },
    queryMyOrders(orderStatus) {
      if (orderStatus === -1 || !orderStatus) {
        orderStatus = "";
      }
      queryOrders(orderStatus, this.currpage, this.pageSize)
        .then((res) => {
          if (!res.result.code) {
            orders = res.result.records;
            this.setOrdersByStatus();
            this.total = this.list.length ? res.result.total : 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.queryMyOrders(this.curStatus);
    },
    handleCurrentChange(val) {
      this.currpage = val;
      this.queryMyOrders(this.curStatus);
    },

    /**
     * 根据当前状态值给界面填充数据
     */
    setOrdersByStatus() {
      switch (this.idx) {
        case 0: //全部
          this.list = orders;
          break;
        case 1:
          this.list = orders.filter((p) => p.status.value === STATUS_PREPAY);
          break;
        case 2:
          this.list = orders.filter((p) => p.status.value === STATUS_COMPLETE);
          break;
        case 3:
          this.list = orders.filter((p) => p.status.value === STATUS_CANCEL);
          break;
      }
    },
    goPay(order) {
      if (
        new Date().getTime() >
        new Date(order.createTime).getTime() + 15 * 60 * 1000
      ) {
        this.dialogFormVisibleMoving = true;
      } else {
        this.$router.push({
          path: "/orderpay",
          query: { orderId: order.orderId },
        });
      }
    },
  },
};
</script>

<style scoped>
@import "../../../assets/css/newCenter.css";
</style>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #fa6400;
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #606266;
}

.c-title {
  text-align: center;
  padding: 20px;
}

.c-title div:nth-child(1) {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.c-title div:nth-child(2) {
  margin-top: 12px;
  font-weight: 400;
  color: #fa6400;
  cursor: pointer;
}

.c-btn {
  display: flex;
  margin-bottom: 20px;
  margin-left: 16px;
  margin-top: 30px;
}

.c-btn div {
  font-size: 14px;
  font-weight: 500;
  padding: 5px 22px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
  border-radius: 2px;
}

.c-btn div:nth-child(1) {
  color: white;
  background: linear-gradient(to left, #fd1d1d, #fcb045);
  margin-right: 20px;
  cursor: pointer;
}

.c-btn div:nth-child(2) {
  border: 1px solid rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
</style>
